import { Box, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import FooterDetail from "./FooterDetail";
import FooterProduct from "./FooterProduct";

import BankImage from "./../../assets/images/bank.png";
import Chrome from "./../../assets/images/browser/chrome.png";
import Edge from "./../../assets/images/browser/edge.png";
import Firefox from "./../../assets/images/browser/firefox.png";
import Opera from "./../../assets/images/browser/opera.png";

const listLink = [
    {
        label: 'ผู้ได้รับรางวัลแจ๊คพ็อต',
        to: '/'
    },
    {
        label: 'ปฏิทินสุด Sexy',
        to: '/'
    },
    {
        label: 'คลับสะสมแต้ม',
        to: '/'
    },
    {
        label: 'โบนัสชวนเพื่อน',
        to: '/'
    },
    {
        label: 'เกี่ยวกับเรา',
        to: '/'
    },
    {
        label: 'เหตุการณ์สำคัญ',
        to: '/'
    },
    {
        label: 'ศูนย์ข้อมูล',
        to: '/'
    },
    {
        label: 'ติดต่อเรา',
        to: '/'
    },
    {
        label: 'พันธมิตร',
        to: '/'
    },
    {
        label: 'บล็อก',
        to: '/'
    }
];

const imgStyles = {
    width: '100%',
    filter: 'grayscale(100%)',
    cursor: 'pointer',
    verticalAlign: 'middle'
};
  
const hoverStyles = {
    filter: 'grayscale(0%)',
};

const Footer = () => {
    return (
        <Box sx={{ backgroundColor: '#0c0c0c', pt: 1 }}>
            <Container>
                <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} sx={
                        {
                            display: {
                                xs: 'none',
                                sm: 'flex',
                                md: 'flex',
                                lg: 'flex'
                            },
                            flexWrap: 'wrap',
                            alignItems: 'baseline'
                        }
                    }>
                        {
                            listLink.map((item, index) => {
                                return (
                                    <>
                                        <Link to={item.to}>
                                            <Typography fontSize={14}>
                                                {item.label}
                                            </Typography>
                                        </Link>
                                        {
                                            index !== (listLink.length - 1) && <>
                                                &nbsp;<Typography fontSize={14} color={`white`}>|</Typography>&nbsp;
                                            </>
                                        }
                                    </>
                                )
                            })
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography display={`flex`} justifyContent={`flex-end`} color={`white`} fontSize={14}>
                            &copy;2021 SPINKUB สงวนลิขสิทธิ์ | 18+
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="line-dotted"></Box>
                    </Grid>
                </Grid>
                <FooterDetail />
                <FooterProduct />
                <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} mb={2}>
                        <Typography color={`white`} fontSize={14} mb={1}>
                            ช่องทางการฝากเงิน
                        </Typography>
                        <img src={BankImage} alt="" style={{ ...imgStyles, transition: 'all .3s', maxWidth: 250 }} onMouseOver={(e) => { e.currentTarget.style.filter = hoverStyles.filter; }} onMouseOut={(e) => { e.currentTarget.style.filter = imgStyles.filter; }} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography color={`white`} fontSize={14} mb={1} display={`flex`} justifyContent={`flex-end`}>
                            เบราว์เซอร์ที่แนะนำ
                        </Typography>
                        <Box display={`flex`} flexWrap={`wrap`} alignItems={`center`} justifyContent={`flex-end`}>
                            <img src={Chrome} alt="" style={{ ...imgStyles, transition: 'all .3s', maxWidth: 30, marginLeft: 5 }} onMouseOver={(e) => { e.currentTarget.style.filter = hoverStyles.filter; }} onMouseOut={(e) => { e.currentTarget.style.filter = imgStyles.filter; }} />
                            <img src={Edge} alt="" style={{ ...imgStyles, transition: 'all .3s', maxWidth: 30, marginLeft: 5 }} onMouseOver={(e) => { e.currentTarget.style.filter = hoverStyles.filter; }} onMouseOut={(e) => { e.currentTarget.style.filter = imgStyles.filter; }} />
                            <img src={Firefox} alt="" style={{ ...imgStyles, transition: 'all .3s', maxWidth: 30, marginLeft: 5 }} onMouseOver={(e) => { e.currentTarget.style.filter = hoverStyles.filter; }} onMouseOut={(e) => { e.currentTarget.style.filter = imgStyles.filter; }} />
                            <img src={Opera} alt="" style={{ ...imgStyles, transition: 'all .3s', maxWidth: 30, marginLeft: 5 }} onMouseOver={(e) => { e.currentTarget.style.filter = hoverStyles.filter; }} onMouseOut={(e) => { e.currentTarget.style.filter = imgStyles.filter; }} />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Footer;
import { Grid, Typography, Box } from "@mui/material";

const detail = [
    {
        title: 'การสมัครสมาชิก',
        detail: 'เปิดบัญชีกับ SPINKUB.com ครบเครื่องทุกความสนุก กับหลากหลายเกมส์เดิมพันออนไลน์, โปรโมชั่นดีๆ และการบริการที่ดีที่สุด สำหรับสมาชิกคนพิเศษเช่นคุณ'
    },
    {
        title: 'พันธมิตร',
        detail: 'ร่วมเป็นพันธมิตรกับเราและรับค่าคอมมิชชั่นสูงได้ทุกเดือนจากผู้เล่นของ SPINKUB'
    },
    {
        title: 'ความรับผิดชอบในการเดิมพัน',
        detail: 'SPINKUB มุ่งมั่นในการนำเสนอช่องทางความบันเทิงให้กับสมาชิกอย่างสูงสุด เรามีระบบรักษาความปลอดภัยชั้นเยี่ยมและความรับผิดชอบที่เชื่อถือได้ในการเดิมพัน'
    },
    {
        title: 'ความปลอดภัย',
        detail: '"SPINKUB.com มีระบบความปลอดภัยและน่าเชื่อถือ ที่สุด ด้วยนโยบายที่ยึดมั่น “ข้อมูลของลูกค้าคือสิ่งสำคัญ"'
    }
];

const product = [
    {
        title: 'ทายผลกีฬา และการเดิมพันออนไลน์',
        detail: 'เราขอเสนอการแข่งขันและกีฬาจากทั่วโลกไม่ว่าจะเป็น พรีเมียร์ลีกอังกฤษ, ลาลีกาสเปน, กัลโช่ซีรี่ย์อา อิตาลี, ยูฟ่าแชมเปี้ยนส์ลีก, ลีกเอิงฝรั่งเศส, เยอรมันบุนเดสลีกา, NFL, NBA, NCAA,บาสเก็ตบอลหญิง, เทนนิส, รถสูตร 1 ฟอร์มูล่าวัน และอีกหลากหลายเกมส์กีฬา'
    },
    {
        title: 'คาสิโนออนไลน์',
        detail: 'ครบเครื่องเรื่องการเดิมพันออนไลน์ ทั้ง รูเล็ต, แบล็คแจ็ค, โป็กเกอร์, สล็อต, เกมส์ต่างๆมากมาย และยังได้ลุ้นเป็นผู้ชนะแจ็คพ็อตใหญ่กับคาสิโนอีกด้วย'
    },
    {
        title: 'คาสิโนสด',
        detail: 'อีกหนึ่งบริการที่ได้รับความนิยม ถ่ายทอดสดจากบรรยากาศจริงวินาทีต่อวินาทีจากดีลเลอร์คาสิโน บาคาร่า, ไพ่ Dragon/Tiger, รูเล็ต, ไฮโล เกมส์สล็อต เล่นเกมส์คีโนที่เล่นง่ายและ เลือกเล่นตามความพอใจ'
    }
];

const center_info = [
    {
        title: 'โปรโมชั่น',
        detail: 'เสนอโปรโมชั่นที่น่าสนใจให้กับท่านสมาชิก ดังนี้ โปรโมชั่นโบนัสเงินฝากครั้งแรก, โปรโมชั่นโบนัสต้อนรับสำหรับลูกค้าใหม่ นอกจากนั้นยังมีโปรโมชั่นเติมเงิน และโปรโมชั่นคืนเงิน ให้กับสมาชิกปัจจุบันอีกด้วย'
    },
    {
        title: 'ศูนย์ช่วยเหลือ',
        detail: 'บริการข้อมูลการทายผลกีฬา, รายละเอียดและกฏของการเดิมพัน, วิธีการเปิดบัญชี, วิธีการฝากถอนเงิน พร้อมเทคนิคการเล่นอีกมากมาย'
    },
    {
        title: 'วิธีการฝาก ถอน',
        detail: 'สมาชิกสามารถ ฝาก-ถอน ได้รวดเร็วทันใจผ่านธนาคารในประเทศ, NETELLER, Moneybrookers, Western Union, และธนาคารระหว่างประเทศ'
    },
    {
        title: 'ติดต่อเรา',
        detail: 'หากท่านสมาชิกต้องการความช่วยเหลือ หรือมีข้อสงสัยเกี่ยวกับการทายผลกีฬาหรือคาสิโนออนไลน์ ท่านสมาชิกสามารถติดต่อเราได้ตลอด24ชั่วโมง ผ่านห้องสนทนาสด, โทรศัพท์ หรือ E-mail'
    }
];

const detail_bet = [
    {
        title: 'ผลกีฬา',
        detail: 'รายงานผลการแข่งขันกีฬาทุกประเภท'
    },
    {
        title: 'ข้อมูลสถิติเพื่อการเดิมพัน',
        detail: 'นำเสนอข้อมูลสถิติการแข่งขันกีฬาจากทั่วโลก ทั้งอันดับในตารางแข่งขัน, สถิติข้อมูลของทีมและผู้เล่น, รายชื่อผู้เล่นบาดเจ็บ รวมไปถึงตารางการแข่งขันในนัดต่อไป'
    },
    {
        title: 'การทายผลกีฬา',
        detail: 'การทายผลกีฬา ยึดถือรายละเอียดข้อบังคับตามกฎระเบียบการแข่งขันกีฬาสากล'
    },
    {
        title: 'การเดิมพันคาสิโน',
        detail: 'คาสิโนออนไลน์ของW88.com มีบริการถ่ายทอดสดจากสถานที่จริง, รายงานผลการเดิมพันย้อนหลัง, ภาพช้าย้อนหลัง, ทุกรายละเอียด ท่านสมาชิกสามารถตรวจสอบได้ด้วยตัวเองผ่านคอมพิวเตอร์ของท่าน ซึ่งสมาชิกสามารถเลือกแสดงผลหรือซ่อนรายละเอียดได้ตามต้องการ'
    }
];

const FooterDetail = () => {
    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography color={`white`} fontSize={14}>
                            ข้อมูล
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="line-dotted"></Box>
                    </Grid>
                    {
                        detail.map((item, index) => {
                            return (
                                <Grid item xs={12} key={index} mt={1}>
                                    <Typography color={`white`} fontSize={14}>
                                        {item.title}
                                    </Typography>
                                    <Typography color={`#7e7e7e`} fontSize={13}>
                                        {item.detail}
                                    </Typography>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography color={`white`} fontSize={14}>
                            ผลิตภัณฑ์
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="line-dotted"></Box>
                    </Grid>
                    {
                        product.map((item, index) => {
                            return (
                                <Grid item xs={12} key={index} mt={1}>
                                    <Typography color={`white`} fontSize={14}>
                                        {item.title}
                                    </Typography>
                                    <Typography color={`#7e7e7e`} fontSize={13}>
                                        {item.detail}
                                    </Typography>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography color={`white`} fontSize={14}>
                            ศูนย์ข้อมูล
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="line-dotted"></Box>
                    </Grid>
                    {
                        center_info.map((item, index) => {
                            return (
                                <Grid item xs={12} key={index} mt={1}>
                                    <Typography color={`white`} fontSize={14}>
                                        {item.title}
                                    </Typography>
                                    <Typography color={`#7e7e7e`} fontSize={13}>
                                        {item.detail}
                                    </Typography>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography color={`white`} fontSize={14}>
                            ข้อมูลการเดิมพัน
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="line-dotted"></Box>
                    </Grid>
                    {
                        detail_bet.map((item, index) => {
                            return (
                                <Grid item xs={12} key={index} mt={1}>
                                    <Typography color={`white`} fontSize={14}>
                                        {item.title}
                                    </Typography>
                                    <Typography color={`#7e7e7e`} fontSize={13}>
                                        {item.detail}
                                    </Typography>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box className="line-dotted"></Box>
            </Grid>
        </Grid>
    )
}

export default FooterDetail;
import { Container, Grid, Typography, Box, Divider } from "@mui/material";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Bar2 from "./../../assets/images/bar2.png";

const HomeDetail = () => {
    return (
        <Container sx={
            {
                mt: 3
            }
        }>
            <Grid container>
                <Grid item xs={12}>
                    <Typography color={`white`} display={`flex`} justifyContent={`center`} fontSize={22} lineHeight={1}>
                        SPINKUB เว็บพนันอันดับ 1 ของไทย
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography color={`white`} display={`flex`} justifyContent={`center`} fontSize={18} lineHeight={1}>
                        คาสิโนออนไลน์ เกมสล็อต กีฬาสด ยิงปลา ระบบออโต้
                    </Typography>
                </Grid>
                <Grid item xs={12} mt={1}>
                    <Box className="line"></Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} mt={2}>
                    <img src={Bar2} alt="" style={{ width: `100%` }} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography color={`white`} fontSize={28} pt={2}>
                        ยินดีต้อนรับเข้าสู่เว็บไซต์ SPINKUB.COM
                    </Typography>
                    <Divider />
                    <Typography color={`white`} mt={1}>
                        เราคือผู้ให้บริการ คาสิโนออนไลน์ สล็อตออนไลน์ เกมส์ยิงปลา เดิมพันกีฬา ครบทุกรูปแบบในเว็บเดียว เราคือเว็บพนันออนไลน์อันดับ 1 ของไทย ด้วยระบบฝากถอนออโต้ 5 วิ รองรับการเดิมพันทุกรูปแบบ บนสมาร์ทโฟน มือถือ และคอมพิวเตอร์
                        ด้วยระบบที่ดีที่สุดในตอนนี้ท่านจะความรู้สึกเสมือนนั่งเดิมพันอยู่ที่
                        คาสิโนต่างประเทศ เรามีเกมส์ทุกรูปแบบ ไม่ว่าจะเป็น บาคาร่า รูเล็ต ไฮโลไทย
                        แทงบอลออนไลน์ อีสปอร์ต เกมสล็อต ยิงปลา ป๊อกเด้ง ปั้นแปะ
                        น้ำเต้าปูปลา
                        พร้อมสาวสวยสุดเซ็กซี่มาแจกไพ่ให้กับคุณ เข้าไปเล่นโดยไม่ต้องโยกย้ายกระเป๋าเงิน
                        รับโบนัสฟรี เล่นได้ทุกเกมส์
                        แจกเครดิตฟรีทุกวันและกิจกรรมประจำวันให้ร่วมอยู่ตลอด ไม่ว่าจะเป็น
                        เปิดไพ่เสี่ยงโชค วงล้อเสี่ยงโชค กล่องสุ่มโชค โบนัสวันเกิด โบนัสเติมเงิน
                        เรายินดีให้บริการลูกค้าทุกท่านด้วยทีมงานคุณภาพมากประสบการณ์
                        ผ่านการทำงานจริง ๆ ที่ในบ่อนต่างประเภท พร้อมให้ความช่วยเหลือตลอด 24 ชั่วโมง
                    </Typography>
                </Grid>
                <Grid item xs={12} py={2} px={3}>
                    <Box display={`flex`} justifyContent={`space-between`}>
                        <Typography color={`white`} display={`flex`} justifyContent={`center`}>
                            <CheckCircleOutlinedIcon sx={
                                {
                                    color: "#ffd700"
                                }
                            } />&nbsp;สมัครสมาชิก
                        </Typography>
                        <Typography color={`white`} display={`flex`} justifyContent={`center`}>
                            <CheckCircleOutlinedIcon sx={
                                {
                                    color: "#ffd700"
                                }
                            } />&nbsp;รับเครดิตฟรี
                        </Typography>
                        <Typography color={`white`} display={`flex`} justifyContent={`center`}>
                            <CheckCircleOutlinedIcon sx={
                                {
                                    color: "#ffd700"
                                }
                            } />&nbsp;เดิมพันได้ทันที
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default HomeDetail;